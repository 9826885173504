.from-check,
.form-check-radio {
  margin-bottom: 12px;
  position: relative;
}

.form-check {
  padding-left: 0;
  margin-bottom: 0.5rem;

  .form-check-label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    padding-left: 35px;
    line-height: 26px;
    margin-bottom: 0;
  }

  .form-check-sign::before,
  .form-check-sign::after {
    content: ' ';
    display: inline-block;
    position: absolute;
    width: 24px;
    height: 24px;
    left: 0;
    cursor: pointer;
    outline: 1px solid var(--border-input-gray);
    top: 0;
    background-color: #fff;
    -webkit-transition: opacity 0.3s linear;
    -moz-transition: opacity 0.3s linear;
    -o-transition: opacity 0.3s linear;
    -ms-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear;
    border-radius: 4px;
  }
  .form-check-sign.gray-checkbox::before,
  .form-check-sign.gray-checkbox::after {
    outline: 1px solid #6d7278;
  }

  .form-check-sign.small::before,
  .form-check-sign.small::after {
    width: 20px;
    height: 20px;
  }

  .form-check-sign.small::before {
    margin: 2px 0px;
  }

  .form-check-sign.small::after {
    margin: -1px 0px;
    font-size: 14px;
    outline: none;
  }

  .form-check-sign::after {
    font-family: 'nucleo-icons';
    content: '\ea21';
    text-align: center;
    font-size: 15px;
    outline: none;
    opacity: 0;
    color: white;
    background-color: black;
  }
  .form-check-sign.gray-checkbox::after {
    color: #6d7278;
  }
  &.disabled {
    .form-check-label {
      color: var(--dark-grey-color);
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.form-check input[type='checkbox'],
.form-check-radio input[type='radio'] {
  opacity: 0;
  position: absolute;
  visibility: hidden;
}
.form-check input[type='checkbox']:checked + .form-check-sign::after {
  opacity: 1;
  background-color: var(--secondary-red-200);
}

.form-control input[type='checkbox']:disabled + .form-check-sign::before,
.checkbox input[type='checkbox']:disabled + .form-check-sign::after {
  cursor: not-allowed;
}

.form-check .form-check-label input[type='checkbox']:disabled + .form-check-sign,
.form-check-radio input[type='radio']:disabled + .form-check-sign {
  pointer-events: none !important;
}

.form-check-radio {
  margin-left: -3px;

  .form-check-label {
    padding-left: 2rem;
  }
  &.disabled {
    .form-check-label {
      color: var(--dark-grey-color);
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.form-check-radio .form-check-sign::before {
  font-family: 'FontAwesome';
  content: '\f10c';
  font-size: 22px;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  position: absolute;
  opacity: 0.5;
  left: 6px;
  top: -4px;
}

.form-check-label input[type='checkbox']:checked + .form-check-sign:before {
  background-color: #fff;
}

.form-check-label input[type='checkbox']:checked + .form-check-sign {
  font-family: var(--font-family-primary);
  font-weight: bold;
}

.form-check-radio input[type='radio'] + .form-check-sign:after,
.form-check-radio input[type='radio'] {
  opacity: 0;
  @include transition-opacity(0.3s, linear);
  content: ' ';
  display: block;
}

.form-check-radio input[type='radio']:checked + .form-check-sign::after {
  font-family: 'FontAwesome';
  content: '\f192';
  top: -5px;
  position: absolute;
  left: 5px;
  opacity: 1;
  font-size: 22px;
}

.form-check-radio input[type='radio']:checked + .form-check-sign::after {
  opacity: 1;
}

.form-check-radio input[type='radio']:disabled + .form-check-sign::before,
.form-check-radio input[type='radio']:disabled + .form-check-sign::after {
  color: var(--dark-grey-color);
}
