.bold-text {
  font-weight: 700 !important;
}

.thin-text {
  font-weight: 100 !important;
}

.light-text {
  font-weight: 300 !important;
}

.primary-color {
  color: var(--primary-color) !important;
}

.secondary-color {
  color: var(--success-color) !important;
}

.error-color {
  color: var(--warning-color) !important;
}

.white-color {
  color: var(--white-color) !important;
}

.muted-color {
  color: var(--muted-color) !important;
}

.gris-9-color {
  color: var(--gris9) !important;
}
.gris-3-color {
  color: var(--gris3) !important;
}

.form-control.no-border {
  border: none !important;
  outline: none !important;
}

.embed-responsive.embed-responsive-80::before {
  padding-top: 80%;
}

/* BACKGROUND */
.bg-success {
  background-color: var(--success-color) !important;
}

.bg-white {
  background-color: var(--white-color) !important;
}

.bg-gris3 {
  background-color: var(--gris3) !important;
}

.bg-gris6 {
  background-color: var(--gris6) !important;
}

.bg-gris7 {
  background-color: var(--gris7) !important;
}

/* TEXT DECORATION */

.btn-space-1 {
  line-height: 1 !important;
}

video {
  position: relative;
}

.text {
  &-underline {
    text-decoration: underline !important;
  }
}

.nav-up {
  @media screen and (min-width: 992px) {
    top: -183px;
  }
  top: -203px;
}

.ratio-1x1 {
  aspect-ratio: 1/1;
}

.ratio-2x1 {
  aspect-ratio: 2/1;
}

.ratio-16x9 {
  aspect-ratio: 1/1;
  @media screen and (min-width: 1400px) {
    aspect-ratio: 16/9;
  }
}

.w-40 {
  width: 40% !important;
}

.rotate-180 {
  transform: rotate(180deg);
}
