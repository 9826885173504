/*--------------------------------

nucleo-icons Web Font - built using nucleoapp.com
License - nucleoapp.com/license/

-------------------------------- */

@font-face {
  font-family: 'nucleo-icons';
  src: url('/assets/fonts/nucleo-icons.eot');
  src:
    url('/assets/fonts/nucleo-icons.eot') format('embedded-opentype'),
    url('/assets/fonts/nucleo-icons.woff2') format('woff2'),
    url('/assets/fonts/nucleo-icons.woff') format('woff'),
    url('/assets/fonts/nucleo-icons.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/*------------------------
	base class definition
-------------------------*/
.nc-icon {
  display: inline-block;
  font: normal normal normal 14px/1 'nucleo-icons';
  font-size: inherit;
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*------------------------
  change icon size
-------------------------*/
.nc-icon.lg {
  font-size: 1.33333333em;
  vertical-align: -16%;
}
.nc-icon.x2 {
  font-size: 2em;
}
.nc-icon.x3 {
  font-size: 3em;
}
/*----------------------------------
  add a square/circle background
-----------------------------------*/
.nc-icon.square,
.nc-icon.circle {
  padding: 0.33333333em;
  vertical-align: -16%;
  background-color: #eee;
}
.nc-icon.circle {
  border-radius: 50%;
}
/*------------------------
  list icons
-------------------------*/
.nc-icon-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none;
}
.nc-icon-ul > li {
  position: relative;
}
.nc-icon-ul > li > .nc-icon {
  position: absolute;
  left: -1.57142857em;
  top: 0.14285714em;
  text-align: center;
}
.nc-icon-ul > li > .nc-icon.lg {
  top: 0;
  left: -1.35714286em;
}
.nc-icon-ul > li > .nc-icon.circle,
.nc-icon-ul > li > .nc-icon.square {
  top: -0.19047619em;
  left: -1.9047619em;
}
/*------------------------
  spinning icons
-------------------------*/
.nc-icon.spin {
  -webkit-animation: nc-icon-spin 2s infinite linear;
  -moz-animation: nc-icon-spin 2s infinite linear;
  animation: nc-icon-spin 2s infinite linear;
}
@-webkit-keyframes nc-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes nc-icon-spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@keyframes nc-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*------------------------
  rotated/flipped icons
-------------------------*/
.nc-icon.rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.nc-icon.rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.nc-icon.rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}
.nc-icon.flip-y {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.nc-icon.flip-x {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: scale(1, -1);
  -moz-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  transform: scale(1, -1);
}
/*------------------------
	font icons
-------------------------*/

.nc-air-baloon::before {
  content: '\ea01';
}

.nc-album-2::before {
  content: '\ea02';
}

.nc-alert-circle-i::before {
  content: '\ea04';
}

.nc-align-center::before {
  content: '\ea03';
}

.nc-align-left-2::before {
  content: '\ea05';
}

.nc-ambulance::before {
  content: '\ea06';
}

.nc-app::before {
  content: '\ea07';
}

.nc-atom::before {
  content: '\ea08';
}

.nc-badge::before {
  content: '\ea09';
}

.nc-bag-16::before {
  content: '\ea0a';
}

.nc-bank::before {
  content: '\ea0b';
}

.nc-basket::before {
  content: '\ea0c';
}

.nc-bell-55::before {
  content: '\ea0d';
}

.nc-bold::before {
  content: '\ea0e';
}

.nc-book-bookmark::before {
  content: '\ea0f';
}

.nc-bookmark-2::before {
  content: '\ea10';
}

.nc-box-2::before {
  content: '\ea11';
}

.nc-box::before {
  content: '\ea12';
}

.nc-briefcase-24::before {
  content: '\ea13';
}

.nc-bulb-63::before {
  content: '\ea14';
}

.nc-bullet-list-67::before {
  content: '\ea15';
}

.nc-bus-front-12::before {
  content: '\ea16';
}

.nc-button-pause::before {
  content: '\ea17';
}

.nc-button-play::before {
  content: '\ea18';
}

.nc-button-power::before {
  content: '\ea19';
}

.nc-calendar-60::before {
  content: '\ea1a';
}

.nc-camera-compact::before {
  content: '\ea1b';
}

.nc-caps-small::before {
  content: '\ea1c';
}

.nc-cart-simple::before {
  content: '\ea1d';
}

.nc-chart-bar-32::before {
  content: '\ea1e';
}

.nc-chart-pie-36::before {
  content: '\ea1f';
}

.nc-chat-33::before {
  content: '\ea20';
}

.nc-check-2::before {
  content: '\ea21';
}

.nc-circle-10::before {
  content: '\ea22';
}

.nc-cloud-download-93::before {
  content: '\ea23';
}

.nc-cloud-upload-94::before {
  content: '\ea24';
}

.nc-compass-05::before {
  content: '\ea25';
}

.nc-controller-modern::before {
  content: '\ea26';
}

.nc-credit-card::before {
  content: '\ea27';
}

.nc-delivery-fast::before {
  content: '\ea28';
}

.nc-diamond::before {
  content: '\ea29';
}

.nc-email-85::before {
  content: '\ea2a';
}

.nc-favourite-28::before {
  content: '\ea2b';
}

.nc-glasses-2::before {
  content: '\ea2c';
}

.nc-globe-2::before {
  content: '\ea2d';
}

.nc-globe::before {
  content: '\ea2e';
}

.nc-hat-3::before {
  content: '\ea2f';
}

.nc-headphones::before {
  content: '\ea30';
}

.nc-html5::before {
  content: '\ea31';
}

.nc-image::before {
  content: '\ea32';
}

.nc-istanbul::before {
  content: '\ea33';
}

.nc-key-25::before {
  content: '\ea34';
}

.nc-laptop::before {
  content: '\ea35';
}

.nc-layout-11::before {
  content: '\ea36';
}

.nc-lock-circle-open::before {
  content: '\ea37';
}

.nc-map-big::before {
  content: '\ea38';
}

.nc-minimal-down::before {
  content: '\ea39';
}

.nc-minimal-left::before {
  content: '\ea3a';
}

.nc-minimal-right::before {
  content: '\ea3b';
}

.nc-minimal-up::before {
  content: '\ea3c';
}

.nc-mobile::before {
  content: '\ea3d';
}

.nc-money-coins::before {
  content: '\ea3e';
}

.nc-note-03::before {
  content: '\ea3f';
}

.nc-palette::before {
  content: '\ea40';
}

.nc-paper::before {
  content: '\ea41';
}

.nc-pin-3::before {
  content: '\ea42';
}

.nc-planet::before {
  content: '\ea43';
}

.nc-refresh-69::before {
  content: '\ea44';
}

.nc-ruler-pencil::before {
  content: '\ea45';
}

.nc-satisfied::before {
  content: '\ea46';
}

.nc-scissors::before {
  content: '\ea47';
}

.nc-send::before {
  content: '\ea48';
}

.nc-settings-gear-65::before {
  content: '\ea49';
}

.nc-settings::before {
  content: '\ea4a';
}

.nc-share-66::before {
  content: '\ea4b';
}

.nc-shop::before {
  content: '\ea4c';
}

.nc-simple-add::before {
  content: '\ea4d';
}

a[aria-expanded='true'] .nc-simple-add::before {
  content: '\ea4e';
}

a[aria-expanded='true'] .nc-minimal-down::before {
  content: '\ea3c';
}

.nc-simple-delete::before {
  content: '\ea4e';
}

.nc-simple-remove::before {
  content: '\ea4f';
}

.nc-single-02::before {
  content: '\ea50';
}

.nc-single-copy-04::before {
  content: '\ea51';
}

.nc-sound-wave::before {
  content: '\ea52';
}

.nc-spaceship::before {
  content: '\ea53';
}

.nc-sun-fog-29::before {
  content: '\ea54';
}

.nc-support-17::before {
  content: '\ea55';
}

.nc-tablet-2::before {
  content: '\ea56';
}

.nc-tag-content::before {
  content: '\ea57';
}

.nc-tap-01::before {
  content: '\ea58';
}

.nc-tie-bow::before {
  content: '\ea59';
}

.nc-tile-56::before {
  content: '\ea5a';
}

.nc-time-alarm::before {
  content: '\ea5b';
}

.nc-touch-id::before {
  content: '\ea5c';
}

.nc-trophy::before {
  content: '\ea5d';
}

.nc-tv-2::before {
  content: '\ea5e';
}

.nc-umbrella-13::before {
  content: '\ea5f';
}

.nc-user-run::before {
  content: '\ea60';
}

.nc-vector::before {
  content: '\ea61';
}

.nc-watch-time::before {
  content: '\ea62';
}

.nc-world-2::before {
  content: '\ea63';
}

.nc-zoom-split::before {
  content: '\ea64';
}

/* all icon font classes list here */

.icon {
  display: block;
  width: 28px;
  height: 28px;
  background-color: black;
  mask-size: cover !important;

  &.icon-sm{
    width: 1.5rem;
    height: 1.5rem;
  }

  &.nav-icon {
    width: 35px;
    height: 35px;
  }

  &.icon-small {
    width: 2rem;
    height: 2rem;
  }

  &.icon-big {
    width: 65px;
    height: 65px;
  }

  &.icon-xl {
    width: 79px;
    height: 79px;
  }

  &.icon-buscador {
    mask: url(/assets/iconos/icons-icon-buscador2.svg) no-repeat center;
  }

  &.icon-tactil {
    mask: url(/assets/iconos/icons-icon-tactil.svg) no-repeat center;
  }

  &.icon-buscar-tel {
    mask: url(/assets/iconos/icons-icon-buscar-tel.svg) no-repeat center;
  }

  &.icon-musica {
    mask: url(/assets/iconos/musica.svg) no-repeat center;
  }

  &.icon-notifications {
    mask: url(/assets/iconos/connected/product/icon_notificaciones.svg) no-repeat center;
  }

  &.icon-buscar-tel {
    mask: url(/assets/iconos/connected/product/icon_camera.svg) no-repeat center;
  }

  &.icon-music-control {
    mask: url(/assets/iconos/connected/product/icon_control_remoto.svg) no-repeat center;
  }

  &.icon-ittt {
    mask: url(/assets/iconos/connected/product/icon_iftt.svg) no-repeat center;
  }

  &.icon-zafiro {
    mask: url(/assets/iconos/connected/product/icon_zafiro2.svg) no-repeat center;
  }

  &.icon-resistente {
    mask: url(/assets/iconos/connected/product/icon_resistente2.svg) no-repeat center;
  }

  &.icon-acero {
    mask: url(/assets/iconos/connected/product/icon_acero2.svg) no-repeat center;
  }

  &.icon-muevete {
    mask: url(/assets/iconos/connected/product/icon_ponte_en_marcha.svg) no-repeat center;
  }

  &.icon-cien {
    mask: url(/assets/iconos/connected/product/icon_cien.svg) no-repeat center;
  }

  &.icon-meta {
    mask: url(/assets/iconos/connected/product/icon_meta_de_actividad.svg) no-repeat center;
  }

  &.icon-cronometro {
    mask: url(/assets/iconos/connected/product/icon-cronometro.svg) no-repeat center;
  }

  &.icon-llevame {
    mask: url(/assets/iconos/connected/product/icon_llevame_a_casa.svg) no-repeat center;
  }

  &.icon-buscar {
    mask: url(/assets/iconos/connected/product/icon_buscar_mi_movil.svg) no-repeat center;
  }

  &.icon-recordar {
    mask: url(/assets/iconos/connected/product/icon_recuerda.svg) no-repeat center;
  }

  &.icon-not-filt {
    mask: url(/assets/iconos/icons-icon-notificaciones-filtradas.svg) no-repeat center;
  }

  &.icon-fecha {
    mask: url(/assets/iconos/icons-icon-fecha.svg) no-repeat center;
  }

  &.icon-alarma {
    mask: url(/assets/iconos/icons-icon-alarma-silenciosa.svg) no-repeat center;
  }

  &.icon-temporizador {
    mask: url(/assets/iconos/icons-icon-temporizador.svg) no-repeat center;
  }

  &.icon-cronometro {
    mask: url(/assets/iconos/icons-icon-cronometro.svg) no-repeat center;
  }

  &.icon-varias-pantalla {
    mask: url(/assets/iconos/icons-icon-varias-pantallas.svg) no-repeat center;
  }

  &.icon-pantalla {
    mask: url(/assets/iconos/icons-icon-pantalla.svg) no-repeat center;
  }

  &.icon-tiempo {
    mask: url(/assets/iconos/icons-icon-monit-sue-o.svg) no-repeat center;
  }

  &.icon-pasos {
    mask: url(/assets/iconos/icons-icon-pasos.svg) no-repeat center;
  }

  &.icon-seguimiento-actividad {
    mask: url(/assets/iconos/icons-icon-seguimiento-de-actividad.svg) no-repeat center;
  }

  &.icon-pongase-firme {
    mask: url(/assets/iconos/icons-icon-pongase-firme.svg) no-repeat center;
  }

  &.icon-frecuencia-cardiaca {
    mask: url(/assets/iconos/icons-icon-freciencia-card-aca.svg) no-repeat center;
  }

  &.icon-cambio-devolucion {
    mask: url(/assets/iconos/icons-icon-devoluci-n-gratuita.svg) no-repeat center;
  }

  &.icon-cesta {
    mask: url(/assets/iconos/icons-icon-cesta2.svg) no-repeat center;
  }

  &.icon-entrega24 {
    mask: url(/assets/iconos/icons-icon-entrega-24-hs.svg) no-repeat center;
  }

  &.icon-pago-seguro {
    mask: url(/assets/iconos/icons-icon-pagos-y-seguridad.svg) no-repeat center;
  }

  &.icon-garantia-2-anios {
    mask: url(/assets/iconos/icons-icon-garant-a-2-a-os.svg) no-repeat center;
  }

  &.icon-entrega-domicilio {
    mask: url(/assets/iconos/icons-icon-entrega-a-domicilio.svg) no-repeat center;
  }

  &.icon-entrega-seur {
    mask: url(/assets/iconos/icons-icon-entrega-punto-seur.svg) no-repeat center;
  }

  &.icon-garant-a-reparaci-n-y-cuidados {
    mask: url(/assets/iconos/garant-a-reparaci-n-y-cuidados.svg) no-repeat center;
  }

  &.icon-pedidos {
    mask: url(/assets/iconos/pedidos.svg) no-repeat center;
  }

  &.icon-cambio-y-devoluci-n {
    mask: url(/assets/iconos/cambio-y-devoluci-n.svg) no-repeat center;
  }

  &.icon-favorito {
    mask: url(/assets/iconos/icons-icon-favoritos2.svg) no-repeat center;
    &.active {
      mask: url(/assets/iconos/icons-icon-favoritos-relleno.svg) no-repeat center;
    }
  }

  &.icon-festina {
    background-image: url(/assets/iconos/group-4.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
  }

  &.icon-location {
    mask: url(/assets/iconos/icons-icon-location.svg) no-repeat center;
  }

  &.icon-video {
    mask: url(/assets/iconos/img-play-video.png) no-repeat center;
  }

  &.arrow-down-icon {
    mask: url(/assets/iconos/arrow-down-icon.svg) no-repeat center;
  }

  &.arrow-up-icon {
    mask: url(/assets/iconos/arrow-up-icon.svg) no-repeat center;
  }

  &.icon-feather-edit {
    mask: url(/assets/iconos/icons-icon-feather-edit.svg) no-repeat center;
  }

  &.icon-close-search {
    mask: url(/assets/iconos/icon-close.svg) no-repeat center;
  }

  &.icon-filter {
    mask: url(/assets/iconos/icon-icon-filter.svg) no-repeat center;
  }
  &.icon-order {
    mask: url(/assets/iconos/icon-filter-organize.svg) no-repeat center;
  }

  &.icon-gift {
    mask: url(/assets/iconos/icon-gift.svg) no-repeat center;
  }

  &.icon-youtube {
    mask: url(/assets/iconos/youtube.svg) no-repeat center;
  }
  &.icon-facebook {
    mask: url(/assets/iconos/facebook.svg) no-repeat center;
  }
  &.icon-xlogo {
    mask: url(/assets/iconos/xlogo.svg) no-repeat center;
  }
  &.icon-linkedin {
    mask: url(/assets/iconos/linkedin.svg) no-repeat center;
  }
  &.icon-whatsapp {
    mask: url(/assets/iconos/whatsapp.svg) no-repeat center;
  }
  &.icon-play-swipper {
    mask: url(/assets/iconos/play_icon.svg) no-repeat center;
  }
  &.icon-pause-swipper {
    mask: url(/assets/iconos/pause_icon.svg) no-repeat center;
  }
  &.icon-check-circle {
    mask: url(/assets/iconos/check_circle.svg) no-repeat center;
  }
  &.icon-plane {
    mask: url(/assets/iconos/airplane.svg) no-repeat center;
  }
  &.icon-device-mobile {
    mask: url(/assets/iconos/device_mobile.svg) no-repeat center;
  }
  &.icon-list {
    mask: url(/assets/iconos/list.svg) no-repeat center;
  }
}
