$urlPath: 'https://media6.festinagroup.com/static/tiendas_online/kronaby/images/assets';
$font-color: #1d1c1b !default;
$fill-font-color: rgba(255, 255, 255, 0.8);

$font-family-secondary: 'DM Sans', 'Helvetica', Arial, sans-serif;
$font-family-primary: 'DM Sans', 'Helvetica', Arial, sans-serif;

/* Primary */
$primary-green-100: #417178;
$primary-green-200: #32575d;
$primary-green-300: #243e42;

/* Secondary */
$secondary-lemon-100: #bac4ba;
$secondary-lemon-200: #b2ccb2;
$secondary-red-100: #c59b96;
$secondary-red-200: #ab6e67;
$secondary-brown-100: #442e2c;

/* Neutrals */
$neutrals-base-white: #fafafa;
$neutrals-base-black: #213336;
$neutrals-grey-100: #f2f7f8;
$neutrals-grey-200: #e7edee;
$neutrals-grey-300: #b6c1c3;
$neutrals-grey-400: #84979a;

/* Success */
$success-100: #6adcca;
$success-200: #15b097;
$success-300: #0b7b69;

/* Warning */
$warning-100: #f4c790;
$warning-200: #eda145;
$warning-300: #cc7914;

$error-100: #e4626f;
$error-200: #c03744;
$error-300: #8c1823;

$primary-color: #000000 !default;
$success-color: #213336 !default;
$muted-color: #a8afb9 !default;
$white-color: #ffffff !default;
$warning-color: #e51414 !default;
$danger-color: #e51414 !default;
$grey-color: #a3a3a3 !default;

$azul1-color: #3b5877 !default;
$azul2-color: #788497 !default;
$azul3-color: #d4d8dc !default;

$gris1-color: #575757 !default;
$gris2-color: #7c7c7c !default;
$gris3-color: #aeaeae !default;
$gris4-color: #d4d4d4 !default;
$gris5-color: #f5f6f6 !default;
$gris6-color: #eaeaea !default;
$gris7-color: #d8d8d8 !default;
$gris8-color: #424f5b !default;
$gris9-color: #85878b !default;

$footer-bottom-bar-color: $neutrals-base-black;
$filter-disabled-text-color: #003a63 !default;

$none: 0 !default;
$border-thin: 1px !default;
$border-thick: 2px !default;

$white-color: #ffffff !default;
$white-bg: #ffffff !default;
$white-states-color: rgba($white-color, 0.8);

$smoke-bg: #f5f5f5 !default;
$pale-bg: #fffcf5 !default;
$medium-pale-bg: #f1eae0 !default;

$table-line-color: #ccc !default;

$black-bg: rgba(30, 30, 30, 0.97) !default;

$black-color: #333333 !default;
$black-hr: #444444 !default;
$black2-color: #1d1d1b !default;

$grayish-orange-color: #d5ccc4 !default;
$light-gray: #e3e3e3 !default;
$medium-gray: #dddddd !default;
$dark-gray: #9a9a9a !default;
$border-imput-gray: #dee2e6 !default;

$placeholder-gray: rgba(210, 210, 210, 1) !default;

$gray-input-bg: #fffcf5 !default;
$danger-input-bg: #ffc0a4 !default;
$success-input-bg: #abf3cb !default;
$other-medium-gray: #a49e93 !default;
$transparent-bg: transparent !default;

$default-color: #66615b !default;
$default-bg: #66615b !default;
$default-states-color: #403d39 !default;

$cesta-color: rgb(175, 175, 175) !default;
$cesta-states-color: rgb(207, 207, 207) !default;

$primary-states-color: darken($primary-color, 10%) !default;

$success-states-color: darken($success-color, 10%) !default;

$info-color: #000000 !default;
$info-states-color: darken($info-color, 10%) !default;

$warning-states-color: darken($warning-color, 10%) !default;

$danger-color: #e83b46 !default;
$danger-states-color: darken($danger-color, 8%) !default;

$link-disabled-color: #666666 !default;

$purple-color: #c178c1 !default;
$purple-states-color: darken($purple-color, 8%) !default;

$brown-color: #dcb285 !default;
$brown-states-color: darken($brown-color, 8%) !default;

/*      light colors - used for select dropdown         */

$light-blue: rgba($primary-color, 0.2);
$light-azure: rgba($info-color, 0.2);
$light-green: rgba($success-color, 0.2);
$light-orange: rgba($warning-color, 0.2);
$light-red: rgba($danger-color, 0.2);

$padding-base-vertical: 7px !default;
$padding-base-horizontal: 18px !default;

$padding-round-vertical: 9px !default;
$padding-round-horizontal: 18px !default;

$padding-simple-vertical: 10px !default;
$padding-simple-horizontal: 18px !default;

$padding-large-vertical: 11px !default;
$padding-large-horizontal: 30px !default;

$padding-small-vertical: 4px !default;
$padding-small-horizontal: 10px !default;

$padding-xs-vertical: 2px !default;
$padding-xs-horizontal: 5px !default;

$padding-label-vertical: 2px !default;
$padding-label-horizontal: 12px !default;

$padding-dropdown-vertical: 10px !default;
$padding-dropdown-horizontal: 15px !default;

$margin-large-vertical: 30px !default;
$margin-base-vertical: 15px !default;

$border-radius-none: 0px !default;
$border-radius-btn-small: 26px !default;
$border-radius-btn-base: 20px !default;
$border-radius-btn-large: 50px !default;

$margin-bottom: 0 0 10px 0 !default;
$border-radius-small: 3px !default;
$border-radius-base: 4px !default;
$border-radius-large: 6px !default;
$border-radius-x-large: 8px !default;
$border-radius-extreme: 0px !default;

$border-radius-none-top: $border-radius-none $border-radius-none 0 0 !default;
$border-radius-none-bottom: 0 0 $border-radius-none $border-radius-none !default;

$border-radius-small-top: $border-radius-small $border-radius-small 0 0 !default;
$border-radius-small-bottom: 0 0 $border-radius-small $border-radius-small !default;

$border-radius-large-top: $border-radius-large $border-radius-large 0 0 !default;
$border-radius-large-bottom: 0 0 $border-radius-large $border-radius-large !default;

$btn-round-radius: 30px !default;

$height-base: 44px !default;

$font-size-base: 12px !default;
$font-size-small: 11px !default;
$font-size-medium: 14px !default;
$font-size-large: 16px !default;
$font-size-large-navbar: 18px !default;

$font-size-h1: 3.563em !default;
$font-size-h2: 2.5em !default;
$font-size-h3: 2.25em !default;
$font-size-h4: 1.875em !default;
$font-size-h5: 1.35em !default;
$font-size-h6: 0.9em !default;
$font-paragraph: 15px !default;
$font-size-navbar: 14px !default;

$font-size-info-horizontal: 2.5em !default;

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-semi: 500 !default;
$font-weight-bold: 600 !default;

$line-height-small: 20px !default;
$line-height-general: 1.5em !default;
$line-height: 36px !default;
$line-height-lg: 54px !default;
$line-height-link-footer: 1.3 !default;
$line-height-link-footer-mobile: 1.5 !default;
$line-height-title-footer: 60px !default;
$line-height-title-footer-mobile: 30px !default;

$border-radius-top: 10px 10px 0 0 !default;
$border-radius-bottom: 0 0 10px 10px !default;
$border-radius-dropdown: 10px 10px !default;

$dropdown-shadow:
  0 2px rgba(17, 16, 15, 0.1),
  0 2px 10px rgba(17, 16, 15, 0.1);

$general-transition-time: 300ms !default;

$slow-transition-time: 300ms !default;
$dropdown-coordinates: 29px -50px !default;

$fast-transition-time: 150ms !default;
$select-coordinates: 50% -40px !default;

$transition-linear: linear !default;
$transition-bezier: cubic-bezier(0.34, 1.61, 0.7, 1) !default;
$transition-ease: ease 0s;

$navbar-padding-a: 10px 15px;
$navbar-margin-a: 15px 3px;

$padding-social-a: 10px 5px;

$navbar-margin-a-btn: 15px 3px;
$navbar-margin-a-btn-round: 16px 3px;

$navbar-padding-a-icons: 6px 15px;
$navbar-margin-a-icons: 6px 3px;

$navbar-padding-brand: 20px 15px;
$navbar-margin-brand: 5px 0px;

$navbar-margin-brand-icons: 12px auto;

$navbar-margin-btn: 15px 3px;

$height-icon: 64px !default;
$width-icon: 64px !default;
$padding-icon: 12px !default;
$border-radius-icon: 15px !default;

$size-icon: 64px;
$size-icon-sm: 32px;

$height-icon-sm: 32px;
$width-icon-sm: 32px;
$padding-icon-sm: 4px;
$border-radius-icon-sm: 7px;

$height-icon-message: 40px;
$width-icon-message: 40px;

$height-icon-message-sm: 20px;
$width-icon-message-sm: 20px;

$white-navbar: rgba(#ffffff, 0.96);
$blue-navbar: rgba(#34acdc, 0.98);
$azure-navbar: rgba(#5bcaff, 0.98);
$green-navbar: rgba(#4cd964, 0.98);
$orange-navbar: rgba(#ff9500, 0.98);
$red-navbar: rgba(#ff4c40, 0.98);

$bg-nude: #fffcf5 !default;
$bg-primary: lighten($primary-color, 7%) !default;
$bg-info: lighten($info-color, 7%) !default;
$bg-success: lighten($success-color, 7%) !default;
$bg-warning: lighten($warning-color, 7%) !default;
$bg-danger: lighten($danger-color, 7%) !default;
$bg-brown: lighten($brown-color, 7%) !default;
$bg-purple: lighten($purple-color, 7%) !default;

$preloader-color: #d8d1c9 !default;

$filter-blue: darken($primary-color, 10%);
$filter-azure: darken($info-color, 10%);
$filter-green: darken($success-color, 10%);
$filter-orange: darken($warning-color, 10%);
$filter-red: darken($danger-color, 10%);

$topbar-x: topbar-x !default;
$topbar-back: topbar-back !default;
$bottombar-x: bottombar-x !default;
$bottombar-back: bottombar-back !default;

$social-facebook: #3b5998;
$social-twitter: #55acee;
$social-pinterest: #cc2127;
$social-google: #dd4b39;
$social-linkedin: #0976b4;
$social-dribbble: #ea4c89;
$social-github: #333333;
$social-youtube: #e52d27;
$social-instagram: #125688;
$social-reddit: #ff4500;
$social-tumblr: #35465c;

$background-light-grey: #e8e7e3 !default;
$background-lighter-grey: #f0efeb !default;
$font-background-light-grey: #9c9b99 !default;
$font-hover-background-light-grey: #5e5e5c !default;

$header-background: #b2afab !default;

$filter-primary: darken($primary-color, 10%);
$filter-info: darken($info-color, 10%);
$filter-success: darken($success-color, 10%);
$filter-warning: darken($warning-color, 10%);
$filter-danger: darken($danger-color, 10%);

$black-footer-bg: #252422 !default;
$brown-font-color: #a49e9e !default;
$brown-font-color-hover: #f1eae0 !default;

$card-black-color: #333333 !default;
$card-muted-color: #ccc !default;

$card-background-blue: #b8d8d8 !default;
$card-font-blue: #506568 !default;
$card-subtitle-blue: #7a9e9f !default;

$card-background-green: #d5e5a3 !default;
$card-font-green: #60773d !default;
$card-subtitle-green: #92ac56 !default;

$card-background-yellow: #ffe28c !default;
$card-font-yellow: #b25825 !default;
$card-subtitle-yellow: #d88715 !default;

$card-background-brown: #d6c1ab !default;
$card-font-brown: #75442e !default;
$card-subtitle-brown: #a47e65 !default;

$card-background-purple: #baa9ba !default;
$card-font-purple: #3a283d !default;
$card-subtitle-purple: #5a283d !default;

$card-background-orange: #ff8f5e !default;
$card-font-orange: #772510 !default;
$card-subtitle-orange: #e95e37 !default;

$card-background-primary: #427c89 !default;
$card-border-color: #427c89 !default;

$color-red-error: rgb(185, 74, 72) !default;
$color-grey-arrow: rgba(204, 204, 204, 0.2) !default;

$width-default: 220px !default;

$zindex-select-dropdown: 1060 !default;

$input-color-placeholder: #999 !default;

$input-height: 48px;

$btn-text-size: 0.7rem;
$btn-text-size-mobile: 0.875rem;
$btn-disabled-background-color: #d4d4d4 !default;
$btn-disabled-color: #788497;

$filter-badge-radius: 16px;

:root {
  --primary-color: #{$primary-color};

  --font-family-primary: #{$font-family-primary};
  --font-family-secondary: #{$font-family-secondary};

  --primary-green-100: #{$primary-green-100};
  --primary-green-200: #{$primary-green-200};
  --primary-green-300: #{$primary-green-300};

  --secondary-lemon-100: #{$secondary-lemon-100};
  --secondary-lemon-200: #{$secondary-lemon-200};
  --secondary-red-100: #{$secondary-red-100};
  --secondary-red-200: #{$secondary-red-200};
  --secondary-brown-100: #{$secondary-brown-100};

  --neutrals-base-white: #{$neutrals-base-white};
  --neutrals-base-black: #{$neutrals-base-black};
  --neutrals-grey-100: #{$neutrals-grey-100};
  --neutrals-grey-200: #{$neutrals-grey-200};
  --neutrals-grey-300: #{$neutrals-grey-300};
  --neutrals-grey-400: #{$neutrals-grey-400};

  --success-100: #{$success-100};
  --success-200: #{$success-200};
  --success-300: #{$success-300};

  --warning-100: #{$warning-100};
  --warning-200: #{$warning-200};

  --error-100: #{$error-100};
  --error-200: #{$error-200};
  --error-300: #{$error-300};

  --primary9: #dee2e6;

  --gris1: #575757;
  --gris2: #7c7c7c;
  --gris3: #aeaeae;
  --gris4: #d4d4d4;
  --gris5: #f5f6f6;
  --gris6: #eaeaea;
  --gris7: #d8d8d8;
  --gris8: #{$gris8-color};
  --gris9: #{$gris9-color};

  --success-color: #{$success-color};
  --warning-color: #{$warning-color};
  --white-color: #{$white-color};
  --muted-color: #{$muted-color};

  --black-hr: #{$black-hr};
  --black2-color: #{$black2-color};

  --step-color: #{$success-color};
  --step-text-color: #{$success-color};
  --step-disabled-color: #{$gris2-color};
  --step-disabled-border: #{$gris2-color};
  --step-disabled-text: #{$gris2-color};
  --step-active: #{$success-color};
  --hello-bar-bg-color: #{$success-color};
  --sidebar-header-color: #{$success-color};

  --filter-slider-color: #{$success-color};
  --filter-disabled-text-color: #{$muted-color};

  --filter-badge-background-color: #{$neutrals-grey-200};
  --filter-badge-color: #{$neutrals-base-black};
  --card-background-color: #{$primary-color};
  --border-color: #{$primary-color};
  --border-color-hover: #{$primary-color};
  --filter-badge-border: #{$filter-badge-radius};

  --footer-bg-color: #{$neutrals-base-black};
  --footer-text-color: #{$neutrals-grey-200};
  --footer-title-font: #{$font-family-secondary};
  --footer-link-font: #{$font-family-secondary};
  --footer-link-color: #{$neutrals-grey-300};
  --footer-botom-bar: #{$footer-bottom-bar-color};
  --footer-bottom-text-color: #{$white-color};
  --footer-title-line-height: #{$line-height-title-footer};
  --footer-link-line-height: #{$line-height-link-footer};
  --footer-border-color: #{$grayish-orange-color};
  --footer-border: #{$white-color};

  --grey-color: #{$grey-color};
  --dark-grey-color: #{$dark-gray};
  --danger-color: #{$danger-color};
  --card-basket-color: #{$gris6-color};
  --border-input-gray: #{$border-imput-gray};

  --jumbotron-consulta-pedido-invitado: url('#{$urlPath}/img/sections/banner_localiza_pedido.jpg');
  --jumbotron-consulta-pedido-invitado-mobile: url('#{$urlPath}/img/sections/banner_localiza_pedido_mobile.jpg');
  --jumbotron-contacto-invitado: url('#{$urlPath}/img/sections/banner_contacto.jpg');
  --jumbotron-contacto-invitado-mobile: url('#{$urlPath}/img/sections/banner_contacto_mobile.jpg');
  --jumbotron-trabajo: url('#{$urlPath}/img/sections/banner_trabaja_con_nosotros.jpg');
  --jumbotron-trabajo-mobile: url('#{$urlPath}/img/sections/banner_trabaja_con_nosotros_mobile.jpg');
  --jumbotron-banner_localizar-pedido: url('#{$urlPath}/img/sections/banner_localiza_pedido.jpg');
  --jumbotron-banner_localizar-pedido-mobile: url('#{$urlPath}/img/sections/banner_localiza_pedido.jpg');
  --jumbotron-banner-contacto: url('#{$urlPath}/img/sections/banner_contacto.jpg');
  --jumbotron-banner-contacto-mobile: url('#{$urlPath}/img/sections/banner_contacto_mobile.jpg');
  --jumbotron-banner-trabaja-con-nosotros: url('#{$urlPath}/img/sections/banner_trabaja_con_nosotros.jpg');
  --jumbotron-banner-trabaja-con-nosotros-mobile: url('#{$urlPath}/img/sections/banner_trabaja_con_nosotros_mobile.jpg');
}
