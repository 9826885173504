@font-face {
  font-family: 'DMSans';
  src: url('../assets/fonts/google/DMSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'DMSans';
  src: url('../assets/fonts/google/DMSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DMSans';
  src: url('../assets/fonts/google/DMSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'DMSans';
  src: url('../assets/fonts/google/DMSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
