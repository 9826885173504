/* Fuentes */
@import 'mixins.scss';
@import '../assets/scss/paper-kit/variables';


h1,
.header-1 {
  @include font-style(2.875rem, black, -0.058rem, $font-family-primary, 3.163rem);
  @include font-mobile-style(2.25rem, -0.045rem, 2.475rem);
  margin-top: 0px !important;
  font-weight: normal;
}

h2,
.header-2 {
  @include font-style(2.375rem, black, -0.048rem, $font-family-primary, 2.613rem);
  @include font-mobile-style(2rem, -0.04rem, 2.2rem);
  margin-top: 0px !important;
  font-weight: normal;
}

h3,
.header-3 {
  @include font-style(2.125rem, black, -0.043rem, $font-family-primary,2.338rem);
  @include font-mobile-style(1.75rem, -0.035rem, 1.925rem);
  margin-top: 0px !important;
  font-weight: normal;
}

h4,
.header-4 {
  @include font-style(1.875rem, black, -0.038rem, $font-family-primary, 2.063rem);
  @include font-mobile-style(1.625rem, -0.033rem, 1.788rem);
  margin-top: 0px !important;
  font-weight: normal;
}

.subtitle-1 {
  @include font-style(1.75rem, black, -0.035rem, $font-family-secondary, 2.275rem);
  @include font-mobile-style(1.5rem, -0.03rem, 1.95rem);
}

.subtitle-2 {
  @include font-style(1.625rem, black, -0.033rem, $font-family-secondary, 2.113rem);
  @include font-mobile-style(1.375rem, -0.028rem, 1.788rem);
  font-weight: normal;
}

.subtitle-3 {
  @include font-style(1.5rem, black, -0.03rem, $font-family-secondary, 1.95rem);
  @include font-mobile-style(1.25rem, -0.025rem, 1.625rem);
  font-weight: normal;
}

.subtitle-4 {
  @include font-style(1.25rem, black, -0.025rem, $font-family-secondary, 1.625rem);
  @include font-mobile-style(1.125rem, -0.023rem, 1.463rem);
}

p,
.paragraph-1 {
  @include font-style(1rem, black, -0.02rem, $font-family-secondary, 1.3rem);
  font-weight: normal;
}

.paragraph-2 {
  @include font-style(0.875rem,black,  -0.018rem, $font-family-secondary, 1.138rem);
  font-weight: normal;
}

.paragraph-3 {
  @include font-style(0.75rem, black, -0.015rem, $font-family-secondary, 0.975rem);
  font-weight: normal;
}

.paragraph-4 {
  @include font-style(0.625rem, black, -0.013rem, $font-family-secondary, 0.813rem);
}

button,
.btn {
  &:not(.fa, .nc-icon) {
    @include font-style-btn(0.75rem, 1.07px, $font-family-secondary);
    font-weight: bold;
  }
}

.titular-blog {
  @include font-style(2.5rem, black, 0px, $font-family-secondary);
  @include font-mobile-style(2rem, 0px);
  font-weight: bold;
}

strong {
  font-weight: 700 !important;
}
